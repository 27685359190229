.quiz {
  display: flex;
  font-size: 2rem;
  overflow: hidden;
  margin-left: 0px;
}

.quiz img {
  max-height: 100%;
  max-width: 100%;
}
.quiz_title {
  font-size: 1rem;
  float: inline-start;
}
.rating {
  font-size: 1rem;
  float: inline-end;
}